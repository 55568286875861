@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Nunito Sans;
  src: url('./fonts/Nunito_Sans/NunitoSans-VariableFont_YTLC\,opsz\,wdth\,wght.ttf') format('woff2');
  font-weight: 600;
}
@font-face {
  font-family: Poppins;
  src: url('./fonts/Poppins/Poppins-Regular.ttf') format('woff2');
  font-weight: 400;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Nunito Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

